<mat-form-field class="block-comment-container">
  <mat-label>Adapter</mat-label>
  <input type="text" placeholder="Adapter" aria-label="Adapter" matInput [formControl]="adapterNameFormControl"
    [matAutocomplete]="autoAdapter">
  <mat-autocomplete #autoAdapter="matAutocomplete">
    <mat-option *ngFor="let adapter of filteredAdapters | async" [value]="adapter">
      {{ adapter }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="block-comment-container">
  <mat-label>Workflow</mat-label>
  <input type="text" placeholder="Workflow" aria-label="Workflow" matInput [formControl]="workflowIdFormControl"
    [matAutocomplete]="autoWorkflow">
  <mat-autocomplete #autoWorkflow="matAutocomplete">
    <mat-option *ngFor="let workflow of filteredWorkflows | async" [value]="workflow">
      {{ workflow }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>


<mat-form-field class="block-comment-container">
  <textarea type="textarea" rows="3" placeholder="Block Comment" aria-label="Block Comment" matInput
    [formControl]="blockCommentFormControl" ></textarea>
</mat-form-field>