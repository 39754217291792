<div style="margin-bottom: 1em;">
  <button mat-raised-button (click)="enableAll()">Enable all</button>
</div>


<section class="row" *ngIf="adapters$ | async as adapters">

  <div *ngFor="let adapter of adapters | objectKeys" class="col-sm-12 col-md-6 col-lg-4">
    <mat-card appearance="outlined" *ngIf="adapters[adapter] as config">
      <mat-card-title>
        {{ config.adapter.title }} [{{ config.adapter.name }}]
      </mat-card-title>

      <mat-card-content>
        <p>
          {{ config.adapter.description }}
        </p>

        <div>
          <p mat-line><b>More info:</b> {{ config.adapter.infoUrl }}</p>
          <p mat-line><b>Maintainer:</b> {{ config.adapter.maintainer }}</p>
          <p mat-line><b>Support:</b> {{ config.adapter.supportUrl }}</p>
        </div>

        <ng-container *ngIf="config.schemas | objectKeys as schemas">
          <div *ngIf="schemas.length > 0">
            <p>
              <b>Supported schemas:</b>
              {{ schemas.join(', ') }}
            </p>
          </div>
        </ng-container>

        <ng-container *ngIf="config.adapter.actions | objectKeys as actions">
          <div *ngIf="actions.length > 0">
            <p>
              <b>Processes:</b>
            </p>
            <ul>
              <li *ngFor="let a of actions">
                <a [routerLink]="['/diagram', config.adapter.name, a]">
                  {{ config.adapter.actions[a].title }}
                </a>
              </li>
            </ul>
          </div>
        </ng-container>

        <div *ngIf="enabled[adapter]; else notEnabled" class="adapter-buttons">
          <button mat-flat-button color="warn" (click)="disableAdapter(adapter)">Disable</button>
          <button mat-stroked-button>Register</button>
          <button mat-stroked-button>Login</button>
          <a mat-stroked-button *ngIf="config?.adapter.swagger" [routerLink]="['/api-client', adapter]">API Client</a>
        </div>
        <ng-template #notEnabled>
          <button mat-flat-button color="primary" (click)="enableAdapter(adapter)">Enable</button>
        </ng-template>

      </mat-card-content>

      <!--<pre>{{ adapters[adapter] | json }}</pre>-->

    </mat-card>
  </div>

</section>

