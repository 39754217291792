
<ng-container *ngIf="field.fieldGroup.length > 0">
  <mat-card appearance="outlined" *ngFor="let currentField of field.fieldGroup; let i = index;">
    <button mat-flat-button (click)="remove(i)">
      <mat-icon>delete</mat-icon>
      <span class="button-label">Remove {{ field.templateOptions?.uiSchema["ui:cardItemLabel"] }}</span>
    </button>
    <formly-field [field]="currentField"></formly-field>
  </mat-card>
</ng-container>

<br>
<button mat-flat-button (click)="add(null, {})">
  <mat-icon>add</mat-icon>
  <span class="button-label">Add {{ field | json}}</span>
</button>
<br>
<pre>{{ field.templateOptions | json }}</pre>
