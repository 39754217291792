
<section class="messagedesk" @enterAnimateUpDwn>



    <mat-card  appearance="outlined"  *ngFor="let message of messageService.messages; let i=index" (click)="closeMe(i)"  @enterAnimateUpDwn
    [ngClass]="message.cssClass" >
        <mat-card-header>
          <mat-card-title i18n="@@helpdesk">Help Desk</mat-card-title>
        </mat-card-header>
        <mat-card-content>
        
          <div>
       
           <i class="fa fa-window-close btn-close btn-close--tl" aria-hidden="true"></i>
           <div *ngIf="i<10" class="card-body" [innerHtml]="message.msgObj.text">
           </div>
       
           <div class="nav-item" *ngIf="message.link?.length">
             <a [routerLink]="message.link" class="nav-link">Please Click Here</a>
           </div>
       
         </div>
      
        </mat-card-content>
        <mat-card-actions>
          <button mat-button>Got it</button>
        </mat-card-actions>
      </mat-card>

</section>

