<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Upload
      </mat-panel-title>
      <mat-icon>cloud_upload</mat-icon>
    </mat-expansion-panel-header>

    <ng-container *ngIf="!isUploading; else uploadProgress">
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title" formControlName="title">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput placeholder="Description" formControlName="description">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select formControlName="category">
            <mat-option *ngFor="let cat of categories$ | async" [value]="cat['id']">
              {{ cat['title'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <input type="file" placeholder="Select file" (change)="onFileChange($event)">

        <mat-radio-group formControlName="privacySetting">
          <mat-radio-button value="private">Private</mat-radio-button>
          <mat-radio-button value="public">Public</mat-radio-button>
          <mat-radio-button value="unlisted">Unlisted</mat-radio-button>
        </mat-radio-group>
      </form>
    </ng-container>

    <ng-template #uploadProgress>
      <mat-progress-bar mode="determinate" [value]="progress$ | async"></mat-progress-bar>
      <p>Waiting for upload...</p>
    </ng-template>


    <mat-action-row>
      <button mat-flat-button [disabled]="form.invalid || isUploading" color="primary" (click)="uploadFile()">Upload</button>
    </mat-action-row>

  </mat-expansion-panel>
</mat-accordion>

<!--<pre>{{ form.getRawValue() | json }}</pre>-->
