
<div class="mat-typography">
  <h3 i18n="welcome msg@@welcomemsg">{{'HOME' | translate }}</h3>







  <!-- <div>
    <h2>{{ 'HOME' | translate }}</h2>
    <label>
      {{ 'HOME.SELECT' | translate }}
      <select #langSelect (change)="translate.use(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
      </select>
    </label>
  </div> -->



<!--  <h4>{{ 'recordings.intro' | translate }}</h4>-->

</div>
