<h1 mat-dialog-title>Add {{ data.type }}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ labelField.label }}</mat-label>
    <input #itemName
           autocomplete="off"
           matInput
           required
           [placeholder]="labelField.label"
           cdkFocusInitial>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [disabled]="itemName.value.length == 0"
          (click)="closeDialog(itemName.value)">Add</button>
</div>
