<mat-dialog-content>
  <mat-form-field style="width: 450px;">
    <mat-label>Select configuration</mat-label>
    <mat-select #configSelect>
      <mat-option *ngFor="let config of configs$ | async" [value]="config.id">
        {{config.title}} ({{ config.created }})
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onLoad(configSelect.value)" style="margin-right: 1em;">Load</button>
  <button mat-flat-button (click)="onCancel()">Cancel</button>
</mat-dialog-actions>

<mat-spinner *ngIf="isLoading"></mat-spinner>
