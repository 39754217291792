/**
* DEPRECATED
* All functionality has been moved to the form builder
*/
<ng-container *ngIf="adapters$ | async as adapters">

  <select [(ngModel)]="selectedAdapter" (ngModelChange)="selectedForm = ''">
    <option class="row" *ngFor="let adapter of adapters | objectKeys | orderKeys" [value]="adapter">
      <ng-container *ngIf="adapters[adapter] as config">{{ config?.adapter?.name }}</ng-container>
    </option>
  </select>

  <ng-container *ngIf="adapters[selectedAdapter]">
    <ng-container *ngIf="adapters[selectedAdapter]['adapter'] as adapterConfig">
      <ng-container *ngIf="adapterConfig['forms'] as formConfig; else noForms">
        <select [(ngModel)]="selectedForm">
          <option *ngFor="let formId of formConfig | objectKeys" [value]="formId">
            {{ formId }}
          </option>
        </select>
      </ng-container>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #noForms>No forms</ng-template>

<br><br>

<div *ngIf="selectedAdapter && selectedForm">
  <app-generic-form [adapter]="selectedAdapter" [formId]="selectedForm"></app-generic-form>
</div>
