

<mat-card appearance="outlined" *ngIf="isAuthenticated; else notLoggedIn">
  <h3>Identities</h3>

  <p>You are logged in as <em>{{ profile?.nickname }}</em></p>

  <br>
  <img *ngIf="profile?.picture" alt="User profile pic" [src]="profile.picture">

  <br><br>
  <p>Last updated: <em>{{ profile?.updated_at }}</em></p>
  <p>Name: <em>{{ profile?.name }}</em></p>
  <p>Connected accounts:
  </p>
  <ul *ngIf="!!profile?.identities">
    <li *ngFor="let a of profile.identities">
      <b>{{ a.provider }}</b>
      <!--({{ a.user_id }})-->
      <ng-container *ngIf="a.provider === 'dropbox'">
        - <a routerLink="/dropbox">Files</a>
      </ng-container>
      <ng-container *ngIf="a.provider === 'google-oauth2'">
        - <a routerLink="/youtube">Videos</a>
      </ng-container>
    </li>
  </ul>
  <pre appDebugOnly>{{ profile | json }}</pre>
  <button mat-flat-button color="primary" (click)="onLogout()">Logout</button>

  <br><br>
  <button mat-flat-button color="primary" (click)="linkAccount()">Link Account</button>
</mat-card>

<ng-template #notLoggedIn>
  <mat-card appearance="outlined">
    <h3>Connected Profiles</h3>

    <p>No profiles are saved on this device. You can connect multiple profiles by
      logging in using different authentication providers.
    </p>
    <button mat-flat-button color="primary" (click)="onLogin()">Login</button>
<!--    <br><br>-->
<!--    <button mat-flat-button color="primary" (click)="loginGoogle()">Login with Google</button>-->
  </mat-card>
</ng-template>

<app-teosto-user-form></app-teosto-user-form>

<!--<app-user-ipn-form></app-user-ipn-form>-->
