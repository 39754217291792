import { Component, OnInit } from '@angular/core';
import {BaseBlockComponent} from '../base-block/base-block.component';

@Component({
  selector: 'app-data-export-block',
  templateUrl: './data-export-block.component.html',
  styleUrls: ['./data-export-block.component.scss']
})
export class DataExportBlockComponent extends BaseBlockComponent {



}
