<div class="mat-typography" style="margin-bottom: 1em;">
  <h2>Temporary page to test API</h2>
</div>

<mat-card appearance="outlined" *ngIf="entityTypes$ | async as entityTypeList">
  <mat-form-field style="margin-right: 1em;">
    <mat-label>Entity type</mat-label>
    <mat-select placeholder="Entity type" (valueChange)="changeEntityType($event)">
      <mat-option *ngFor="let type of entityTypeList" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button [disabled]="isLoadingAll" (click)="importEverything()" color="primary" type="button">Import all</button>
</mat-card>

<mat-card appearance="outlined" *ngIf="entityList$ | async as entityList">
  <mat-form-field style="margin-right: 0.5em;">
    <mat-label>Entity ID</mat-label>
    <mat-select placeholder="Entity ID" (valueChange)="changeEntity($event)">
      <mat-option *ngFor="let entity of entityList" [value]="entity.id">
        {{ entity['id'] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button style="margin-right: 0.5em;" type="button" mat-stroked-button (click)="listAll()">Fetch all</button>
  <button mat-flat-button (click)="importAll()" color="primary" type="button">Import all {{ selectedType }}</button>
</mat-card>

<mat-card appearance="outlined" *ngIf="selectedEntity$ | async as entity">
  <pre>{{ entity | json }}</pre>
</mat-card>

<mat-card appearance="outlined" *ngIf="listAll$ | async as allItems">
  <pre>{{ allItems | json }}</pre>
</mat-card>
