/**
 *
 * @namespace faker.hacker
 */
var Hacker = function (faker) {
  var self = this;

  /**
   * abbreviation
   *
   * @method faker.hacker.abbreviation
   */
  self.abbreviation = function () {
    return faker.random.arrayElement(faker.definitions.hacker.abbreviation);
  };

  /**
   * adjective
   *
   * @method faker.hacker.adjective
   */
  self.adjective = function () {
    return faker.random.arrayElement(faker.definitions.hacker.adjective);
  };

  /**
   * noun
   *
   * @method faker.hacker.noun
   */
  self.noun = function () {
    return faker.random.arrayElement(faker.definitions.hacker.noun);
  };

  /**
   * verb
   *
   * @method faker.hacker.verb
   */
  self.verb = function () {
    return faker.random.arrayElement(faker.definitions.hacker.verb);
  };

  /**
   * ingverb
   *
   * @method faker.hacker.ingverb
   */
  self.ingverb = function () {
    return faker.random.arrayElement(faker.definitions.hacker.ingverb);
  };

  /**
   * phrase
   *
   * @method faker.hacker.phrase
   */
  self.phrase = function () {
    var data = {
      abbreviation: self.abbreviation,
      adjective: self.adjective,
      ingverb: self.ingverb,
      noun: self.noun,
      verb: self.verb
    };
    var phrase = faker.random.arrayElement(faker.definitions.hacker.phrase);
    return faker.helpers.mustache(phrase, data);
  };
  return self;
};
module['exports'] = Hacker;