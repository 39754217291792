var address = {};
module['exports'] = address;
address.city_prefix = require("./city_prefix");
address.city_suffix = require("./city_suffix");
address.city_name = require("./city_name");
address.county = require("./county");
address.country = require("./country");
address.country_code = require("./country_code");
address.country_code_alpha_3 = require("./country_code_alpha_3");
address.building_number = require("./building_number");
address.street_suffix = require("./street_suffix");
address.secondary_address = require("./secondary_address");
address.postcode = require("./postcode");
address.postcode_by_state = require("./postcode_by_state");
address.state = require("./state");
address.state_abbr = require("./state_abbr");
address.time_zone = require("./time_zone");
address.city = require("./city");
address.street_name = require("./street_name");
address.street_address = require("./street_address");
address.default_country = require("./default_country");
address.direction = require("./direction");
address.direction_abbr = require("./direction_abbr");