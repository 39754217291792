<div mat-dialog-title>
  Select Task
</div>
<div mat-dialog-content>
  <div class="button-selections">
    <ng-container *ngFor="let b of blockTypes">
      <button mat-flat-button
              [matTooltip]="b.label"
              [color]="(selectedBlockType && selectedBlockType.type == b.type) ? 'primary' : undefined"
              (click)="setBlockType(b)">
        <app-kendraio-icon [icon]="b.icon"></app-kendraio-icon>
        {{b.label}}
      </button>
    </ng-container>
  </div>

  <mat-card appearance="outlined">
    <ng-container *ngIf="!!selectedBlockType">
      <h3>{{ selectedBlockType.label }}</h3>
      <p>{{ selectedBlockType.description }}</p>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions class="dialog-buttons">
  <button mat-flat-button (click)="addBlock()" color="primary" [disabled]="!selectedBlockType" data-cy="dialog-addBlock-addTask-button">
    Add Task
  </button>
  <button mat-flat-button (click)="cancel()" cdkFocusInitial>Cancel</button>
</div>
