<mat-list *ngIf="docs$ | async as docs">
  <cdk-virtual-scroll-viewport itemSize="50" class="docs-viewport" *ngIf="docs.rows.length > 0; else noContent">
    <div *cdkVirtualFor="let doc of docs.rows" class="doc-item">
      <span class="doc-schema">{{ doc.id.split(':')[0].split('_')[1] }}</span>
      <span class="doc-label"><a [routerLink]="['/docs', doc.id]">{{ doc.key }}</a></span>
      <span class="doc-operations">
        <a mat-icon-button [routerLink]="['/docs', doc.id]">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button (click)="deleteDoc(doc)">
          <mat-icon>delete</mat-icon>
        </a>
      </span>
    </div>
  </cdk-virtual-scroll-viewport>
</mat-list>

<ng-template #noContent>
  <div class="mat-typography">
    <p>No documents found in database.</p>
  </div>
</ng-template>


<div class="add-content-button">
  <button mat-fab (click)="addContent()">
    <mat-icon>add</mat-icon>
  </button>
</div>
