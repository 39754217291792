<div *ngIf="isLoading">
  <p>Loading...</p>
</div>
<app-grid-block *ngIf="!isLoading" [config]="config" [model]="connectionManager.flows"></app-grid-block>

<div class="preview-message">
    <a routerLink="/user">
      <mat-icon>person</mat-icon>
      <span>click here for the old account page</span>
    </a>
</div>
