import { __assign } from "tslib";
import { noCase } from "no-case";
export function pascalCaseTransform(input, index) {
  var firstChar = input.charAt(0);
  var lowerChars = input.substr(1).toLowerCase();
  if (index > 0 && firstChar >= "0" && firstChar <= "9") {
    return "_" + firstChar + lowerChars;
  }
  return "" + firstChar.toUpperCase() + lowerChars;
}
export function pascalCaseTransformMerge(input) {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}
export function pascalCase(input, options) {
  if (options === void 0) {
    options = {};
  }
  return noCase(input, __assign({
    delimiter: "",
    transform: pascalCaseTransform
  }, options));
}
