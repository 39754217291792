// JS-YAML's default schema for `safeLoad` function.
// It is not described in the YAML specification.
//
// This schema is based on standard YAML's Core schema and includes most of
// extra types described at YAML tag repository. (http://yaml.org/type/)

'use strict';

var Schema = require('../schema');
module.exports = new Schema({
  include: [require('./core')],
  implicit: [require('../type/timestamp'), require('../type/merge')],
  explicit: [require('../type/binary'), require('../type/omap'), require('../type/pairs'), require('../type/set')]
});