<mat-form-field>
  <mat-label *ngIf="fieldLabel">{{ fieldLabel }}</mat-label>
  <mat-select [value]="defaultValue" (valueChange)="onValueChange($event)">
    <mat-option *ngIf="!required"></mat-option>
    <mat-option *ngFor="let v of values" [value]="v[valueField]">
      {{ labelGetter ? (v | mapping:labelGetter) : v[labelField] }}
    </mat-option>
  </mat-select>
</mat-form-field>



<!--{{ defaultValue }}-->
