<mat-card appearance="outlined">
  <p>
    Delete all data...
  </p>

  
  <button (click)="resetApp()" mat-raised-button color="warn">Reset App</button>
</mat-card>

<mat-card appearance="outlined">
  <p>Debug mode <b>{{ isDebug ? 'enabled' : 'disabled' }}</b></p>

  <button mat-raised-button color="primary" (click)="toggleDebugMode()">Toggle debug mode</button>
</mat-card>




<mat-card appearance="outlined">
  <p>Show Help<b> {{ showHelp ? 'on' : 'off' }}</b></p>

  <button mat-raised-button color="primary" (click)="toggleShowHelp()">Toggle Show Help</button>
</mat-card>

