<ag-grid-angular *ngIf="enabled"
                 #gridAngular style="width: 100%;"
                 class="ag-theme-alpine"
                 domLayout="autoHeight"
                 [rowData]="rowData"
                 [defaultColDef]="defaultColDef"
                 [gridOptions]="gridOptions"
                 [components]="components"
                 (selectionChanged)="onSelectionChanged($event)"
                 [columnDefs]="columnDefs"></ag-grid-angular>


