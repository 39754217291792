"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnumType = void 0;
var EnumType = function () {
  function EnumType(value) {
    this.value = value;
  }
  return EnumType;
}();
exports.EnumType = EnumType;
