<select [(ngModel)]="selectedApi" (ngModelChange)="getList()">
  <option *ngFor="let api of APIs" [value]="api">{{ api }}</option>
</select>
<br>
<ng-container *ngIf="dataList.length > 0">
  <select [(ngModel)]="selectedData">
    <option *ngFor="let d of dataList; let i = index" [value]="i">
      {{ d?.title || d?.originalTitle }}
    </option>
  </select>
</ng-container>
<br><br>
<button mat-flat-button (click)="loadData()" color="primary">Load Data</button>
<button mat-flat-button (click)="cancel()">Cancel</button>
