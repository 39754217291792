import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-doc-dialog',
  templateUrl: './add-doc-dialog.component.html',
  styleUrls: ['./add-doc-dialog.component.scss']
})
export class AddDocDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
