

<ng-container *ngIf="blockTypeConfig">
  <ngx-monaco-editor *ngIf="hasEditor" [options]="editorOptions" style="height: 460px;"
                     [(ngModel)]="blockModel" (onInit)="initEditor()"></ngx-monaco-editor>
</ng-container>

<mat-form-field class="block-comment-container">
  <mat-label>Block Comment</mat-label>
  <textarea type="textarea" rows="2" aria-label="Block Comment" matInput
    [formControl]="blockCommentFormControl" ></textarea>
</mat-form-field>
