<input type="file" #filePicker (change)="onFileChange($event)">
<button class="mb-1" type="button" mat-stroked-button (click)="filePicker.click()">
  {{ file ? 'Replace' : 'Add' }} file
</button>

<button type="button" mat-icon-button (click)="playPause()">
  <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
</button>

<div class="audio-wrapper" *ngIf="!!file">
  <app-waveform #player [file]="file"></app-waveform>
</div>

<ul>
  <li *ngFor="let d of clipControl.value; index as i">
    <div class="dragging-container" [appTrackClip]="d" (clipUpdate)="onClipUpdate(i, $event)">
      <span>{{ d.name }}</span>
      <div class="clip-buttons">
        <button (click)="editClip(i)" type="button" mat-icon-button>
          <mat-icon aria-label="Edit clip">settings</mat-icon>
        </button>
        <button (click)="removeClip(i)" type="button" mat-icon-button>
          <mat-icon aria-label="Remove clip">delete</mat-icon>
        </button>
      </div>
    </div>
  </li>
</ul>

<mat-form-field>
  <mat-label>Add clip</mat-label>
  <input matInput type="text" (keydown.enter)="add(name.value); name.value = ''" #name placeholder="Add clip">
  <mat-icon matSuffix (click)="add(name.value); name.value = ''" class="add-icon">add_box</mat-icon>
</mat-form-field>

<div style="display: block;">
  <canvas [appDoughnutChart]="clipValues"></canvas>
</div>
