<p class="mat-typography">Load form from Adapter:</p>
<mat-dialog-content>
  <ng-container *ngIf="adapters$ | async as adapters">

    <select [(ngModel)]="selectedAdapter" (ngModelChange)="selectedForm = ''">
      <option class="row" *ngFor="let adapter of adapters | objectKeys | orderKeys" [value]="adapter">
        <ng-container *ngIf="adapters[adapter] as config">{{ config?.adapter?.name }}</ng-container>
      </option>
    </select>

    <ng-container *ngIf="adapters[selectedAdapter]">
      <ng-container *ngIf="adapters[selectedAdapter]['adapter'] as adapterConfig">
        <ng-container *ngIf="adapterConfig['forms'] as formConfig; else noForms">
          <select [(ngModel)]="selectedForm">
            <option *ngFor="let formId of formConfig | objectKeys" [value]="formId">
              {{ formId }}
            </option>
          </select>
        </ng-container>
      </ng-container>
    </ng-container>

  </ng-container>

  <ng-template #noForms>No forms</ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button (click)="loadForm()" color="primary">Load Form</button>
  <button mat-flat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
