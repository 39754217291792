'use strict';

var Type = require('../../type');
function resolveJavascriptUndefined() {
  return true;
}
function constructJavascriptUndefined() {
  /*eslint-disable no-undefined*/
  return undefined;
}
function representJavascriptUndefined() {
  return '';
}
function isUndefined(object) {
  return typeof object === 'undefined';
}
module.exports = new Type('tag:yaml.org,2002:js/undefined', {
  kind: 'scalar',
  resolve: resolveJavascriptUndefined,
  construct: constructJavascriptUndefined,
  predicate: isUndefined,
  represent: representJavascriptUndefined
});