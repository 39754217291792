import { __assign } from "tslib";
import { pascalCase, pascalCaseTransform, pascalCaseTransformMerge } from "pascal-case";
export function camelCaseTransform(input, index) {
  if (index === 0) return input.toLowerCase();
  return pascalCaseTransform(input, index);
}
export function camelCaseTransformMerge(input, index) {
  if (index === 0) return input.toLowerCase();
  return pascalCaseTransformMerge(input);
}
export function camelCase(input, options) {
  if (options === void 0) {
    options = {};
  }
  return pascalCase(input, __assign({
    transform: camelCaseTransform
  }, options));
}
