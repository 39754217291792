/**
 *
 * @namespace faker.helpers
 */
var Helpers = function (faker) {
  var self = this;

  /**
   * backward-compatibility
   *
   * @method faker.helpers.randomize
   * @param {array} array
   */
  self.randomize = function (array) {
    array = array || ["a", "b", "c"];
    return faker.random.arrayElement(array);
  };

  /**
   * slugifies string
   *
   * @method faker.helpers.slugify
   * @param {string} string
   */
  self.slugify = function (string) {
    string = string || "";
    return string.replace(/ /g, '-').replace(/[^\一-龠\ぁ-ゔ\ァ-ヴー\w\.\-]+/g, '');
  };

  /**
   * parses string for a symbol and replace it with a random number from 1-10
   *
   * @method faker.helpers.replaceSymbolWithNumber
   * @param {string} string
   * @param {string} symbol defaults to `"#"`
   */
  self.replaceSymbolWithNumber = function (string, symbol) {
    string = string || "";
    // default symbol is '#'
    if (symbol === undefined) {
      symbol = '#';
    }
    var str = '';
    for (var i = 0; i < string.length; i++) {
      if (string.charAt(i) == symbol) {
        str += faker.datatype.number(9);
      } else if (string.charAt(i) == "!") {
        str += faker.datatype.number({
          min: 2,
          max: 9
        });
      } else {
        str += string.charAt(i);
      }
    }
    return str;
  };

  /**
   * parses string for symbols (numbers or letters) and replaces them appropriately (# will be replaced with number,
   * ? with letter and * will be replaced with number or letter)
   *
   * @method faker.helpers.replaceSymbols
   * @param {string} string
   */
  self.replaceSymbols = function (string) {
    string = string || "";
    var alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var str = '';
    for (var i = 0; i < string.length; i++) {
      if (string.charAt(i) == "#") {
        str += faker.datatype.number(9);
      } else if (string.charAt(i) == "?") {
        str += faker.random.arrayElement(alpha);
      } else if (string.charAt(i) == "*") {
        str += faker.datatype.boolean() ? faker.random.arrayElement(alpha) : faker.datatype.number(9);
      } else {
        str += string.charAt(i);
      }
    }
    return str;
  };

  /**
   * replace symbols in a credit card schems including Luhn checksum
   *
   * @method faker.helpers.replaceCreditCardSymbols
   * @param {string} string
   * @param {string} symbol
   */

  self.replaceCreditCardSymbols = function (string, symbol) {
    // default values required for calling method without arguments
    string = string || "6453-####-####-####-###L";
    symbol = symbol || "#";

    // Function calculating the Luhn checksum of a number string
    var getCheckBit = function (number) {
      number.reverse();
      number = number.map(function (num, index) {
        if (index % 2 === 0) {
          num *= 2;
          if (num > 9) {
            num -= 9;
          }
        }
        return num;
      });
      var sum = number.reduce(function (prev, curr) {
        return prev + curr;
      });
      return sum % 10;
    };
    string = faker.helpers.regexpStyleStringParse(string); // replace [4-9] with a random number in range etc...
    string = faker.helpers.replaceSymbolWithNumber(string, symbol); // replace ### with random numbers

    var numberList = string.replace(/\D/g, "").split("").map(function (num) {
      return parseInt(num);
    });
    var checkNum = getCheckBit(numberList);
    return string.replace("L", checkNum);
  };

  /** string repeat helper, alternative to String.prototype.repeat.... See PR #382
  *
  * @method faker.helpers.repeatString
  * @param {string} string
  * @param {number} num
  */
  self.repeatString = function (string, num) {
    if (typeof num === "undefined") {
      num = 0;
    }
    var text = "";
    for (var i = 0; i < num; i++) {
      text += string.toString();
    }
    return text;
  };

  /**
   * parse string patterns in a similar way to RegExp
   *
   * e.g. "#{3}test[1-5]" -> "###test4"
   *
   * @method faker.helpers.regexpStyleStringParse
   * @param {string} string
   */
  self.regexpStyleStringParse = function (string) {
    string = string || "";
    // Deal with range repeat `{min,max}`
    var RANGE_REP_REG = /(.)\{(\d+)\,(\d+)\}/;
    var REP_REG = /(.)\{(\d+)\}/;
    var RANGE_REG = /\[(\d+)\-(\d+)\]/;
    var min, max, tmp, repetitions;
    var token = string.match(RANGE_REP_REG);
    while (token !== null) {
      min = parseInt(token[2]);
      max = parseInt(token[3]);
      // switch min and max
      if (min > max) {
        tmp = max;
        max = min;
        min = tmp;
      }
      repetitions = faker.datatype.number({
        min: min,
        max: max
      });
      string = string.slice(0, token.index) + faker.helpers.repeatString(token[1], repetitions) + string.slice(token.index + token[0].length);
      token = string.match(RANGE_REP_REG);
    }
    // Deal with repeat `{num}`
    token = string.match(REP_REG);
    while (token !== null) {
      repetitions = parseInt(token[2]);
      string = string.slice(0, token.index) + faker.helpers.repeatString(token[1], repetitions) + string.slice(token.index + token[0].length);
      token = string.match(REP_REG);
    }
    // Deal with range `[min-max]` (only works with numbers for now)
    //TODO: implement for letters e.g. [0-9a-zA-Z] etc.

    token = string.match(RANGE_REG);
    while (token !== null) {
      min = parseInt(token[1]); // This time we are not capturing the char before `[]`
      max = parseInt(token[2]);
      // switch min and max
      if (min > max) {
        tmp = max;
        max = min;
        min = tmp;
      }
      string = string.slice(0, token.index) + faker.datatype.number({
        min: min,
        max: max
      }).toString() + string.slice(token.index + token[0].length);
      token = string.match(RANGE_REG);
    }
    return string;
  };

  /**
   * takes an array and randomizes it in place then returns it
   * 
   * uses the modern version of the Fisher–Yates algorithm
   *
   * @method faker.helpers.shuffle
   * @param {array} o
   */
  self.shuffle = function (o) {
    if (typeof o === 'undefined' || o.length === 0) {
      return o || [];
    }
    o = o || ["a", "b", "c"];
    for (var x, j, i = o.length - 1; i > 0; --i) {
      j = faker.datatype.number(i);
      x = o[i];
      o[i] = o[j];
      o[j] = x;
    }
    return o;
  };

  /**
   * mustache
   *
   * @method faker.helpers.mustache
   * @param {string} str
   * @param {object} data
   */
  self.mustache = function (str, data) {
    if (typeof str === 'undefined') {
      return '';
    }
    for (var p in data) {
      var re = new RegExp('{{' + p + '}}', 'g');
      str = str.replace(re, data[p]);
    }
    return str;
  };

  /**
   * createCard
   *
   * @method faker.helpers.createCard
   */
  self.createCard = function () {
    return {
      "name": faker.name.findName(),
      "username": faker.internet.userName(),
      "email": faker.internet.email(),
      "address": {
        "streetA": faker.address.streetName(),
        "streetB": faker.address.streetAddress(),
        "streetC": faker.address.streetAddress(true),
        "streetD": faker.address.secondaryAddress(),
        "city": faker.address.city(),
        "state": faker.address.state(),
        "country": faker.address.country(),
        "zipcode": faker.address.zipCode(),
        "geo": {
          "lat": faker.address.latitude(),
          "lng": faker.address.longitude()
        }
      },
      "phone": faker.phone.phoneNumber(),
      "website": faker.internet.domainName(),
      "company": {
        "name": faker.company.companyName(),
        "catchPhrase": faker.company.catchPhrase(),
        "bs": faker.company.bs()
      },
      "posts": [{
        "words": faker.lorem.words(),
        "sentence": faker.lorem.sentence(),
        "sentences": faker.lorem.sentences(),
        "paragraph": faker.lorem.paragraph()
      }, {
        "words": faker.lorem.words(),
        "sentence": faker.lorem.sentence(),
        "sentences": faker.lorem.sentences(),
        "paragraph": faker.lorem.paragraph()
      }, {
        "words": faker.lorem.words(),
        "sentence": faker.lorem.sentence(),
        "sentences": faker.lorem.sentences(),
        "paragraph": faker.lorem.paragraph()
      }],
      "accountHistory": [faker.helpers.createTransaction(), faker.helpers.createTransaction(), faker.helpers.createTransaction()]
    };
  };

  /**
   * contextualCard
   *
   * @method faker.helpers.contextualCard
   */
  self.contextualCard = function () {
    var name = faker.name.firstName(),
      userName = faker.internet.userName(name);
    return {
      "name": name,
      "username": userName,
      "avatar": faker.internet.avatar(),
      "email": faker.internet.email(userName),
      "dob": faker.date.past(50, new Date("Sat Sep 20 1992 21:35:02 GMT+0200 (CEST)")),
      "phone": faker.phone.phoneNumber(),
      "address": {
        "street": faker.address.streetName(true),
        "suite": faker.address.secondaryAddress(),
        "city": faker.address.city(),
        "zipcode": faker.address.zipCode(),
        "geo": {
          "lat": faker.address.latitude(),
          "lng": faker.address.longitude()
        }
      },
      "website": faker.internet.domainName(),
      "company": {
        "name": faker.company.companyName(),
        "catchPhrase": faker.company.catchPhrase(),
        "bs": faker.company.bs()
      }
    };
  };

  /**
   * userCard
   *
   * @method faker.helpers.userCard
   */
  self.userCard = function () {
    return {
      "name": faker.name.findName(),
      "username": faker.internet.userName(),
      "email": faker.internet.email(),
      "address": {
        "street": faker.address.streetName(true),
        "suite": faker.address.secondaryAddress(),
        "city": faker.address.city(),
        "zipcode": faker.address.zipCode(),
        "geo": {
          "lat": faker.address.latitude(),
          "lng": faker.address.longitude()
        }
      },
      "phone": faker.phone.phoneNumber(),
      "website": faker.internet.domainName(),
      "company": {
        "name": faker.company.companyName(),
        "catchPhrase": faker.company.catchPhrase(),
        "bs": faker.company.bs()
      }
    };
  };

  /**
   * createTransaction
   *
   * @method faker.helpers.createTransaction
   */
  self.createTransaction = function () {
    return {
      "amount": faker.finance.amount(),
      "date": new Date(2012, 1, 2),
      //TODO: add a ranged date method
      "business": faker.company.companyName(),
      "name": [faker.finance.accountName(), faker.finance.mask()].join(' '),
      "type": self.randomize(faker.definitions.finance.transaction_type),
      "account": faker.finance.account()
    };
  };
  return self;
};

/*
String.prototype.capitalize = function () { //v1.0
    return this.replace(/\w+/g, function (a) {
        return a.charAt(0).toUpperCase() + a.substr(1).toLowerCase();
    });
};
*/

module['exports'] = Helpers;