<div class="toolbar">
  <button type="button" mat-icon-button (click)="toggleFormConfig()" matTooltip="Configure form">
    <mat-icon>settings</mat-icon>
  </button>
  <button type="button" mat-icon-button (click)="shareForm()" matTooltip="Share Form">
    <mat-icon>share</mat-icon>
  </button>
  <button type="button" mat-icon-button (click)="loadFromSwagger()" matTooltip="Load Form from Swagger">
    <mat-icon>cloud_download</mat-icon>
  </button>
  <button type="button" mat-icon-button (click)="loadFromAdapter()" matTooltip="Load Form from Adapter">
    <mat-icon>open_in_browser</mat-icon>
  </button>
  <button type="button" mat-icon-button (click)="loadFromDatabase()" matTooltip="Load data">
    <mat-icon>folder</mat-icon>
  </button>
  <button type="button" mat-icon-button (click)="loadDataFromAPI()" matTooltip="Load data from API">
    <mat-icon>mood</mat-icon>
  </button>
  <button type="button" mat-icon-button (click)="resetModelData()" matTooltip="Reset model data">
    <mat-icon>settings_backup_restore</mat-icon>
  </button>
</div>

<div class="editor-wrapper">

  <div class="editor-panels editor-column" [hidden]="!showFormConfig">
    <span class="editor-title">JSON Schema</span>
    <ngx-monaco-editor class="json-code-editor" [options]="editorOptions"

                       [(ngModel)]="JSONSchema"
                       (ngModelChange)="jsonSchemaChange()" (onInit)="initJson()"></ngx-monaco-editor>
    <span class="editor-title">UI Schema</span>
    <ngx-monaco-editor [options]="editorOptions" class="uischema-editor"

                       [(ngModel)]="UISchema"
                       (ngModelChange)="jsonSchemaChange()"></ngx-monaco-editor>
    <div class="model-wrapper">
      <span class="editor-title">Model Output</span>
      <div class="model-output" #modelOutput></div>
    </div>
  </div>
  <div class="form-output editor-column">
    <mat-error *ngIf="hasError">
      {{ errorMessage }}
    </mat-error>
    <formly-form [fields]="fields" [options]="options" [model]="model" [form]="form" (modelChange)="onChange()"></formly-form>
    <div class="form-actions">
      <button mat-flat-button color="primary" (click)="onSubmit()" *ngIf="!isDbForm && !isAPIData">Submit</button>
      <button mat-flat-button color="primary" (click)="onSaveAPI()" *ngIf="isAPIData">Save (API)</button>
      <button mat-flat-button color="primary" (click)="onSave()" *ngIf="isDbForm">Save (DB)</button>
      <button mat-flat-button (click)="resetForm()" *ngIf="isDbForm">Reset</button>
    </div>

<pre>
<!-- {{fields | json}} -->
</pre>

  </div>

</div>
