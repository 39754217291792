<mat-error *ngIf="hasError">
  {{ errorMessage }}
</mat-error>

<mat-error *ngIf="contextErrorKey && contextErrors">{{contextErrors}}</mat-error>

<div *ngIf="errorBlocks.length > 0">
  <app-blocks-workflow [blocks]="errorBlocks"
                       [models]="[errorData]"
                       [context]="context"></app-blocks-workflow>
</div>

<div class="spinner-wrapper">
  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>