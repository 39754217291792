<div class="mat-typography" style="margin-bottom: 1em;">
  <h2>Import from Bloomen API</h2>
</div>

<mat-card appearance="outlined">
  <mat-form-field style="margin-right: 1em;">
    <mat-label>Publisher</mat-label>
    <mat-select placeholder="Example user" [(ngModel)]="activeUser">
      <mat-option [value]="'Publisher'">Publisher</mat-option>
      <mat-option [value]="'Photographer'">Photographer</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button (click)="login()" color="primary" type="button">Login as selected user</button>
</mat-card>

<mat-card appearance="outlined" *ngIf="userInfo$">
  <button class="button-row-item" mat-flat-button color="primary" type="button" (click)="importPhotos()">Import Photos</button>
  <button *ngIf="activeUser == 'Publisher'" mat-flat-button color="primary" type="button" (click)="importUsers()">Import Users</button>
  <pre>{{ userInfo$ | async | json }}</pre>
</mat-card>
