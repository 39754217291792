// Source: http://unicode.org/cldr/trac/browser/tags/release-27/common/main/en.xml#L1847
module["exports"] = {
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  // Property "wide_context" is optional, if not set then "wide" will be used instead
  // It is used to specify a word in context, which may differ from a stand-alone word
  wide_context: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  abbr: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  // Property "abbr_context" is optional, if not set then "abbr" will be used instead
  // It is used to specify a word in context, which may differ from a stand-alone word
  abbr_context: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
};